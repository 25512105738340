import { RefObject, useLayoutEffect } from "react";

export const useBrowserBarcodeDetector = (videoElement: RefObject<HTMLVideoElement>,
                                          onValueScanned: (value: string) => void,
                                          valueValidator: (value: string) => boolean
                                              = (_value: string) => true) => {
    const barcodeDetectorInstance = new BarcodeDetector();

    return useLayoutEffect(() => {
        const timer = setInterval((async () => {
            if (!videoElement.current || !videoElement.current.readyState) {
                return;
            }

            const barcodes = (await barcodeDetectorInstance.detect(videoElement.current))
                .filter(barcode => barcode.format !== "qr_code")
                .map(barcode => barcode.rawValue);

            for (const barcode of barcodes) {
                if (valueValidator(barcode)) {
                    onValueScanned(barcode);
                    clearInterval(timer);
                }
            }
        }), 250);

        return () => clearInterval(timer);
    }, [videoElement]);
};
